import React from 'react'
import './footer.css'
import { FaFacebookF } from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { IoLogoTwitter } from 'react-icons/io';

const Footer = () => {
  return (
    <footer>
      <a href="/" className='footer__logo'>TANIN</a>

      <ul className='permalinks'>
        <li><a href='/'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href='https://facebook.com/atanin'><FaFacebookF /></a>
        <a href='https://instagram.com/atanin'><FiInstagram /></a>
        <a href='https://twitter.com/atanin'><IoLogoTwitter /></a>
      </div>

      <div className="footer__copyright">
        <p>@TANIN, 2023</p>
      </div>
    </footer>
  )
}

export default Footer